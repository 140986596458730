import React from 'react'
import styled from 'styled-components'

import Image from './Image'
import Card from './Card'
import IconStar from '../assets/icn-star.svg'
import { mediaQuery } from '../utils/media-queries'

const Wrapper = styled.section`
    background-color: ${props => props.theme.brandColor2};
    position: relative;
    padding-bottom: 5rem;

    ${mediaQuery.isBig`
        &:before {
            background-color: #fff;
            content: '';
            height: 100%;
            left: 50%;
            position: absolute;
            transform: translateX(-2px);
            width: 4px;
        }
    `}
`

const Quotes = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
`

const Blockquote = styled.blockquote`
    margin-bottom: 6rem;
    flex-basis: 327px;
    flex-grow: 1;
    position: relative;

    ${mediaQuery.isBig`
        margin-bottom: 0;
    `}

    ${mediaQuery.isHuge`
        max-width: 450px;
    `}

    ${mediaQuery.extraHuge`
        max-width: 550px;
    `}

    .card {
        align-items: center;
        display: flex;
        height: 200px;
        margin: 0 auto 2rem;
        padding: 20px;
        width: 200px;
    }

    p {
        color: #fff;
    }
`

const Title = styled.h2`
    align-items: center;
    background-color: #fff;
    border-radius: 40px;
    display: flex;
    height: 80px;
    justify-content: space-between;
    margin: 0 auto 2rem;
    padding: 0 0.75rem;
    transform: translateY(-38px);
    width: 300px;
    position: relative;

    ${mediaQuery.isBig`
        margin-bottom: 0;
    `}

    span {
        display: block;
        left: -200%;
        overflow: hidden;
        position: absolute;
        width: 0;
    }

    ${mediaQuery.isBig`
        .curve,
        .curve:after {
            position: absolute;
            content: '';
        }

        .curve {
            border-bottom: 0;
            border-left: 0;
            border: solid 20px #fff;
            height: 40px;
            left: 50%;
            top: 80px;
            transform: translateX(-42px);
            width: 40px;

            &:after {
                background-color: ${props => props.theme.brandColor2};
                border-radius: 80px;
                height: 80px;
                right: -20px;
                top: -20px;
                width: 80px;
            }

            &.right {
                left: calc(50% + 2px);
                transform: scaleX(-1);
            }
        }
    `}
`

const Logo = ({ name }) => {
    return (
        <Card className="card">
            <Image name={name} width={200} />
        </Card>
    )
}

const QuoteMark = styled.span`
    font-size: 15rem;
    font-family: Georgia, Times, "Times New Roman", serif;
    opacity: 0.15;
    position: absolute;
    left: -2rem;
`

const QuoteIcon = () => <QuoteMark>“</QuoteMark>;

const Testimonals = ({ className, id }) => {
    return (
        <Wrapper className={className}>
            <Title>
                <span>Testimoniales</span>
                <IconStar />
                <IconStar />
                <IconStar />
                <IconStar />
                <IconStar />
                <div className="curve left"></div>
                <div className="curve right"></div>
            </Title>
            <Quotes id={id}>
                <Blockquote>
                    <QuoteIcon />
                    <Logo name="logoEmerson" />
                    <p>
                        La Pulpe mejoró significativamente el ambiente general
                        de nuestra empresa, la productividad de los
                        colaboradores se incremento 10% año sobre año.
                    </p>
                    <footer>— Facilities Manager</footer>
                </Blockquote>
                <Blockquote>
                    <QuoteIcon />
                    <Logo name="logoSmile" />
                    <p>
                        Nuestros colaboradores manifiestan constantemente como
                        La Pulpe ha mejorado su calidad de vida en el trabajo y
                        eso se proyecta en la productividad.
                    </p>
                    <footer>— Facilities Manager</footer>
                </Blockquote>
            </Quotes>
        </Wrapper>
    )
}

export default Testimonals
