import React, { useState } from 'react'
import styled from 'styled-components'
import IconArrow from '../assets/icn-arrow.svg'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const Ul = styled.ul`
    margin: 0;
    list-style: none;

    button {
        appearance: none;
        border: 0;
        padding: 0;
        background: none;
        padding: 0;
        text-align: left;
    }

    h3 {
        align-items: center;
        cursor: pointer;
        color: ${props => props.theme.brandColor1Dark};
        display: flex;
        margin-bottom: 0.75rem;

        svg {
            margin-right: 1rem;
            flex-shrink: 0;
        }
    }

    p {
        height: 0;
        margin-left: calc(32px + 1rem);
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
        transition: opacity 200ms ease-in-out;

        &.active {
            height: auto;
            margin-bottom: 2rem;
            opacity: 1;
            transform: translateY(0);
        }
    }
`

const AccordionItem = ({ onClick, open, title, content }) => {
    return (
        <>
            <button type="button" onClick={onClick} onKeyDown={() => {}}>
                <h3>
                    {open ? (
                        <IconArrow
                            style={{
                                transform: 'rotate(180deg)',
                                transition: 'transform 200ms ease',
                            }}
                        />
                    ) : (
                        <IconArrow
                            style={{ transition: 'transform 300ms ease' }}
                        />
                    )}{' '}
                    {title}
                </h3>
            </button>

            <p className={open ? 'active' : null}>{content}</p>
        </>
    )
}

const Accordion = ({ data }) => {
    const [current, setCurrent] = useState(0)
    return (
        <Ul>
            {data.map((item, i) => {
                return (
                    <li key={i}>
                        <AccordionItem
                            onClick={() => {
                                setCurrent(i)
                                trackCustomEvent({
                                    // string - required - The object that was interacted with (e.g.video)
                                    category: 'About Us',
                                    // string - required - Type of interaction (e.g. 'play')
                                    action: 'Click',
                                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                    label: item.title,
                                })
                            }}
                            open={current === i}
                            {...item}
                        />
                    </li>
                )
            })}
        </Ul>
    )
}

export default Accordion
