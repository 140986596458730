import React from 'react'

import styled from 'styled-components'

const Svg = ({ color }) => {
    return (
        <svg
            style={{ display: 'block' }}
            width="100%"
            viewBox="0 0 1440 210"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="Page"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g id="Curve" fill={color || '#9CBF3B'}>
                    <path
                        d="M0,16.4005983 C233.336668,156.646512 552.437354,16.4005983 806.47504,16.4005983 C1060.51273,16.4005983 1225.81798,210 1440,210 C1440,115.640371 1440,45.6403709 1440,0 L0,0"
                        id="The-Path"
                        transform="translate(720.000000, 105.000000) rotate(-180.000000) translate(-720.000000, -105.000000) "
                    ></path>
                </g>
            </g>
        </svg>
    )
}

const CurveWrapper = styled.div`
    margin-bottom: -2px;
    position: relative;

    svg {
        position: absolute;
        top: 0;
    }

    .light {
        svg {
            position: static;
            transform: scaleX(-1);
        }
    }
`

const Curve = () => {
    return (
        <CurveWrapper>
            <div className="light">
                <Svg color="#EBF2D8" />
            </div>
            <Svg />

        </CurveWrapper>
    )
}

export default Curve
