import React from 'react'
import CTAButton from './CTAButton'
import Image from './Image'
import styled from 'styled-components'
import { mediaQuery } from '../utils/media-queries'
import { scroller } from 'react-scroll'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

const Wrapper = styled.div`
    color: ${props => props.theme.white};
    display: flex;
    flex-wrap: wrap-reverse;

    h1,
    p {
        ${mediaQuery.isBig`
            text-align: right;
        `}
    }

    h1 {
        color: ${props => props.theme.brandColor1Hightlight};
        font-size: 1.15rem;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
    }

    p {
        font-size: 1.8rem;
    }

    .content__wrapper {
        flex-basis: 55%;
        display: flex;
        flex-grow: 1;

        ${mediaQuery.isBig`
            padding-bottom: 1rem;
        `}
    }

    .content {
        background: ${props => props.theme.brandColor2};
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        padding: var(--spacing-global);

        ${mediaQuery.isBig`
            border-bottom-right-radius: 2rem;
            align-items: flex-end;
        `}

        button {
            align-self: center;

            ${mediaQuery.isBig`
                align-self: flex-end;
            `}
        }
    }

    .picture {
        border-bottom-left-radius: 2rem;
        flex-basis: 45%;
        flex-grow: 1;
        min-width: 307px;
        overflow: hidden;

        img {
            object-position: left center !important; /* For Gatsby Image */
        }
    }

    .gatsby-image-wrapper {
        height: 100%;
    }
`
const LandingBanner = props => {
    return (
        <Wrapper {...props}>
            <div className="picture">
                <Image name="landing01" />
            </div>
            <div className="content__wrapper">
                <div className="content">
                    <h1>Abrimos y administramos Tiendas de conveniencia</h1>
                    <p>
                        Ofrecemos los snacks y bebidas que necesitan tus
                        colaboradores para mantenerse{' '}
                        <b>productivos y felices</b>.
                    </p>
                    <CTAButton
                        onClick={() => {
                            trackCustomEvent({
                                // string - required - The object that was interacted with (e.g.video)
                                category: 'CTA',
                                // string - required - Type of interaction (e.g. 'play')
                                action: 'Click',
                                // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                                label: 'Conversemos'
                            })

                            scroller.scrollTo('contact', {
                                duration: 750,
                                delay: 0,
                                smooth: true,
                                offset: -130,
                            })
                        }}
                    >
                        Conversemos
                    </CTAButton>
                </div>
            </div>
        </Wrapper>
    )
}

export default LandingBanner
