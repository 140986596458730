import React from 'react'

import styled from 'styled-components'

const CardWrapper = styled.div`
    background: #ffffff;
    box-shadow: 0 10px 19px 0 rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
`

const Card = (props) => {
    const { children, className } = props;
    return <CardWrapper {...props} className={className}>{children}</CardWrapper>
}

export default Card
