import React from 'react'
import Accordion from './Accordion'
import Image from './Image'
import Curve from './Curve'

import styled from 'styled-components'

const Section = styled.section`
    color: ${props => props.theme.brandColor1Dark};
    background-color: ${props => props.theme.brandColor1};
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap-reverse;
    padding-top: 4rem;

    .content {
        flex-basis: 55%;
        flex-grow: 1;
        justify-content: center;
        padding: 0 var(--spacing-global) 4rem;

        h2 {
            
            margin-left: 0;
            margin-right: 0;
        }
    }

    .picture {
        flex-basis: 45%;
        flex-grow: 1;
        overflow: hidden;
        min-width: 307px;
        position: relative;
        max-height: 100%;
    }
`

const AboutUs = ({ className, id }) => {
    return (
        <>
            <Curve />
            <Section className={className}>
                <div className="picture">
                    <Image name="landing03" />
                </div>

                <div className="content">
                    <div id={id}>
                        <h2>Tiendas a su medida</h2>
                        <p>
                            Operamos <b>tiendas de conveniencia</b> dentro de su
                            empresa en tiempo record, con productos de calidad y
                            el mejor servicio al cliente.
                        </p>
                        <Accordion
                            data={[
                                {
                                    title: 'Más de 1000 productos disponibles',
                                    content:
                                        'Nuestro amplio rango de precios nos permite atender a todos tipo de clientes, pudiendo ampliar nuestro stock según las necesidades y limitaciones de los consumidores.',
                                },
                                {
                                    title: 'Sin filas',
                                    content:
                                        'Nuestro sistema nos permite hacer ventas en menos de 1 minuto, así que sus colaboradores no pasarán mucho tiempo en la fila.',
                                },
                                {
                                    title: 'Nos ajustamos a cualquier espacio',
                                    content:
                                        'Podemos operar desde en kioscos diseñados para el espacio asignado o espacios de mayores dimensiones, ajustando nuestro punto de venta a las necesidades de su empresa.',
                                },
                                {
                                    title: 'Aperturas en 48 horas',
                                    content:
                                        'Sabemos que estamos en el negocio de la conveniencia, por eso podemos iniciar operaciones en 48 horas.',
                                },
                            ]}
                        />
                    </div>
                </div>
            </Section>
        </>
    )
}

export default AboutUs
