import React from 'react'
import Image from './Image'
import Card from './Card'
import styled from 'styled-components'
import { mediaQuery } from '../utils/media-queries'

import IconBattery from '../assets/icn-battery.svg'
import IconRocket from '../assets/icn-rocket.svg'
import IconClock from '../assets/icn-clock.svg'

const Section = styled.section`
    margin-bottom: -7rem;
    position: relative;
    z-index: 1;
    

    ${mediaQuery.isHuge`
        margin-bottom: -9rem;
    `}

    ${mediaQuery.extraHuge`
        margin-bottom: -12rem;
    `}

    h2 {
        ${mediaQuery.isBig`
            text-align: center;
        `}
    }
`

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 2rem;

    b {
        border-bottom: solid 4px ${props => props.theme.brandColor2};
    }

    p {
        font-size: 1.25rem;
        margin-left: var(--spacing-global);
        margin-right: var(--spacing-global);

        ${mediaQuery.isBig`
            margin-left: 0;
            margin-right: 0;
        `}

        br {
            display: none;

            ${mediaQuery.isBig`
                display: block;
            `}
        }
    }

    .content {
        display: flex;
        flex-basis: 55%;
        flex-grow: 1;
        justify-content: center;
    }

    .picture {
        flex-basis: 45%;
        flex-grow: 1;
        min-width: 307px;
        overflow: hidden;
        position: relative;
        padding: 2.25rem 0 0 0;

        &:before,
        &:after {
            content: '';
            position: absolute;
            height: 100%;
        }

        &:after {
            background: ${props => props.theme.brandColor1};
            border-top-left-radius: 2rem;
            left: 1.5rem;
            top: 0;
            right: 1rem;
            width: 90%;
            opacity: 0.2;
        }

        &:before {
            border: solid 2px ${props => props.theme.brandColor1};
            border-top-left-radius: 1.25rem;
            left: 2.75rem;
            top: 1rem;
            width: 100%;
        }

        .gatsby-image-wrapper {
            z-index: 1;
        }

        img {
            object-position: left center !important; /* For Gatsby Image */
        }
    }
`

const Cards = styled.div`
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(var(--spacing-global) / 2);
    margin-right: calc(var(--spacing-global) / 2);
    max-width: 1300px;


    ${mediaQuery.isHuge`
        margin: 0 auto;
    `}


    .card {
        flex: 1;
        min-width: 280px;
        padding: 60px 1.5rem 3rem;
        margin: 2rem calc(var(--spacing-global) / 2);
        position: relative;

        svg {
            top: -1rem;
            position: absolute;
            height: 64px;
        }
    }
`

const Benefits = props => {
    return (
        <Section {...props}>
            <h2>Empresas Inteligentes</h2>
            <Wrapper>
                <div className="content">
                    <p>
                        Aplique estrategias que usan <br />
                        grandes empresas como{' '}
                        <b>
                            Google y <br />
                            Facebook
                        </b>{' '}
                        para asegurar un salario <br />
                        emocional que garantice la <br />
                        fidelidad de sus colaboradores.
                    </p>
                </div>
                <div className="picture">
                    <Image name="landing02" />
                </div>
            </Wrapper>

            <Cards>
                <Card className="card">
                    <IconRocket />
                    <p>Personal más activo y eficiente.</p>
                </Card>

                <Card className="card">
                    <IconBattery />
                    <p>
                        Los alimentos y bebidas mantienen a las personas
                        energizadas.
                    </p>
                </Card>

                <Card className="card">
                    <IconClock />
                    <p>
                        Conveniente para los colaboradores, convenientes para
                        las empresas.
                    </p>
                </Card>
            </Cards>
        </Section>
    )
}

export default Benefits
