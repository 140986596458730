import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

const Image = ({ name }) => (
    <StaticQuery
        query={graphql`
            query {
                landing01: file(
                    relativePath: { eq: "images/tiendas-corporativas-01.jpg" }
                ) {
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                landing02: file(
                    relativePath: { eq: "images/tiendas-corporativas-02.jpg" }
                ) {
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                landing03: file(
                    relativePath: { eq: "images/tiendas-corporativas-03.jpg" }
                ) {
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                logoSmile: file(relativePath: { eq: "logo-smile.png" }) {
                    childImageSharp {
                        # Specify a fixed image and fragment.
                        # The default width is 400 pixels
                        fixed(width: 160) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
                logoEmerson: file(relativePath: { eq: "logo-emerson.png" }) {
                    childImageSharp {
                        # Specify a fixed image and fragment.
                        # The default width is 400 pixels
                        fixed(width: 160) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={data => {
            return name === 'logoSmile' || name === 'logoEmerson' ? (
                <Img fixed={data[name].childImageSharp.fixed} />
            ) : (
                <Img fluid={data[name].childImageSharp.fluid} />
            )
        }}
    />
)
export default Image
