import React, { useState } from 'react'
import styled from 'styled-components'

import Card from './Card'
import Button from './styled/Button'
import Field from './styled/Field'
import FormButtonWrapper from './styled/FormButtonWrapper'

import submitForm from '../utils/submitForm'

const Wrapper = styled.section`
    padding: 0 2rem;
    transform: translateY(-2rem);

    h2 {
        text-align: center;
        margin-bottom: 3rem;
    }

    form {
        padding: 0 2rem;
        margin: 0;
    }

    .card {
        margin: 0 auto;
        max-width: 700px;
        padding: 2rem 0;
        position: relative;
    }
`



const getLabelClass = valid => (valid ? 'required invalid' : 'required')

const ContactUs = props => {
    const initialValue = {
        contact: '',
        phone: '',
        email: '',
        company: '',
        employees: '',
    }

    const [validity, setValidity] = useState({
        contact: false,
        phone: false,
        email: false,
        company: false,
        form: false,
    })
    const [formValue, setFormValue] = useState(initialValue)
    const [thanks, setThanks] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const onChangeHandler = e => {
        setFormValue({
            ...formValue,
            [e.target.id]: e.target.value,
        })
    }

    return (
        <Wrapper {...props}>
            <Card className="card">
                <div>
                    <h2>Conversemos</h2>
                    <form
                        onSubmit={e => {
                            setLoading(true)
                            e.preventDefault()
                            submitForm('6IXRNTTSpINEk0_Oa_t3l', formValue)
                                .then(() => {
                                    setLoading(false)
                                    setFormValue(initialValue)
                                    setThanks(true)
                                })
                                .catch(() => {
                                    setLoading(false)
                                    setError(true)
                                })
                        }}
                        onInput={e => {
                            setThanks(false)
                            setError(false)
                            if (e.target.checkValidity) {
                                setValidity({
                                    ...validity,
                                    [e.target.id]: !e.target.checkValidity(),
                                    form: e.currentTarget.checkValidity(),
                                })
                            }
                        }}
                    >
                        <Field
                            htmlFor="contact"
                            className={getLabelClass(validity.contact)}
                        >
                            <span>Persona Contacto</span>
                            <input
                                type="text"
                                id="contact"
                                required
                                value={formValue.contact}
                                onFocus={e => {
                                    e.target.parentNode.classList.add('focus')
                                }}
                                onBlur={e => {
                                    e.target.parentNode.classList.remove(
                                        'focus'
                                    )
                                }}
                                onChange={onChangeHandler}
                            />
                        </Field>
                        <Field htmlFor="phone">
                            <span>Telefono</span>
                            <input
                                type="tel"
                                id="phone"
                                value={formValue.phone}
                                onFocus={e => {
                                    e.target.parentNode.classList.add('focus')
                                }}
                                onBlur={e => {
                                    e.target.parentNode.classList.remove(
                                        'focus'
                                    )
                                }}
                                onChange={onChangeHandler}
                            />
                        </Field>
                        <Field
                            htmlFor="email"
                            className={getLabelClass(validity.email)}
                        >
                            <span>Correo Electronico</span>
                            <input
                                type="email"
                                id="email"
                                required
                                value={formValue.email}
                                onFocus={e => {
                                    e.target.parentNode.classList.add('focus')
                                }}
                                onBlur={e => {
                                    e.target.parentNode.classList.remove(
                                        'focus'
                                    )
                                }}
                                onChange={onChangeHandler}
                            />
                        </Field>
                        <Field
                            htmlFor="company"
                            className={getLabelClass(validity.company)}
                        >
                            <span>Empresa</span>
                            <input
                                type="text"
                                id="company"
                                value={formValue.company}
                                required
                                onFocus={e => {
                                    e.target.parentNode.classList.add('focus')
                                }}
                                onBlur={e => {
                                    e.target.parentNode.classList.remove(
                                        'focus'
                                    )
                                }}
                                onChange={onChangeHandler}
                            />
                        </Field>
                        <Field
                            htmlFor="employees"
                            className={getLabelClass(validity.employees)}
                        >
                            <span>Cantidad de colaboradores</span>
                            <select
                                required
                                id="employees"
                                onChange={onChangeHandler}
                                onFocus={e => {
                                    e.target.parentNode.classList.add('focus')
                                }}
                                onBlur={e => {
                                    e.target.parentNode.classList.remove(
                                        'focus'
                                    )
                                }}
                            >
                                <option value=""></option>
                                <option value="300-500">300-500</option>
                                <option value="500-1000">500-1000</option>
                                <option value="mas-de-1000">Mas de 1000</option>
                            </select>
                        </Field>
                        <FormButtonWrapper>
                            <Button
                                disabled={!validity.form}
                            >
                                Enviar
                            </Button>
                            <p className={loading ? 'show' : ''}>Enviando...</p>
                            <p className={thanks ? 'show' : ''}>
                                ¡Muchas Gracias! Muy pronto le contactamos.
                            </p>
                            <p className={error ? 'error show' : ''}>
                                Disculpe, ocurrió un error puedes llamarnos al
                                72155831.
                            </p>
                        </FormButtonWrapper>
                    </form>
                </div>
            </Card>
        </Wrapper>
    )
}

export default ContactUs
