import React from 'react'
import styled from 'styled-components'
import { Element } from 'react-scroll'

import Layout from '../components/Layout'
import LandingBanner from '../components/LandingBanner'
import Benefits from '../components/Benefits'
import AboutUs from '../components/AboutUs'
import Testimonals from '../components/Testimonals'
import ContactUs from '../components/ContactUs'

const Main = styled.main`
    & > .spaced {
        margin-bottom: 3rem;
    }
`

const QueHacemos = ({ location }) => (
    <Layout seourl={location.pathname} seotitle="Convenience Store Mini Market Tienda de Conveniencia">
        <Main>
            <LandingBanner className="spaced" />
            <Benefits id="servicios" />
            <AboutUs id="nosotros" />
            <Testimonals id="clientes" />
            <Element name="contact" />
            <ContactUs id="contacto" />
        </Main>
    </Layout>
)

export default QueHacemos
